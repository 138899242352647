import dayjs from 'dayjs';
import { ceil, compact, max, maxBy, min, sum } from 'lodash-es';

import { i18nFormat, ReceiveAddress } from '../../..';
import { Id } from '../../common';
import { CurrencySymbolMap, CurrencySymbolType } from '../../common/currency';
import { D3ModelFile } from '../../common/file';
import { parseReceiverName } from '../../crm/customer';
import { get, isValidArray } from '../../utils';
import { handleRFQPriceStringWithOption } from './utils';
import { ShopExpress } from '../express';
import {
  convertDeliverAddressToReceiveAddress,
  InquiryOrder,
  InquiryOrderItem,
  InquiryOrderStatus,
} from '../inquiry-order';
import { calcAddressId } from '../inquiry-order/delivery';
import { ShopOrder } from '../shop-order';
import { ShopOrderQuotationRfqReasonType } from '../shop-order/quotation/ShopOrderQuotation';

export interface OrderLeadTimes {
  maxLeadtimeInDays: number;
  minLeadtimeInDays: number;
  shipmentDate: string;
  dueDate: string;
  maxLeadTimeStr: string;
}

export const isOrderQuotedStatus = (status: InquiryOrderStatus) => {
  return !(['WAIT_REVIEW', 'WAIT_SUBMIT'] as InquiryOrderStatus[]).includes(
    status,
  );
};

export function calcEstDeliverTime(
  estDeliveryTime: string | undefined,
  rfq: boolean,
) {
  const prefix = '';
  if (estDeliveryTime == null || estDeliveryTime.length === 0) {
    return `${prefix}2-3 ${i18nFormat('Business days')}`;
  } else {
    if (estDeliveryTime.toLowerCase().includes('business')) {
      return `${prefix}${estDeliveryTime}`;
    } else {
      return `${prefix}${estDeliveryTime.replace('days', 'Business days')}`;
    }
  }
}

export function calcOrderLeadTimes(order: InquiryOrder): OrderLeadTimes {
  const spuList = !!order ? order.items.map(item => item.materialSpu) : [];

  /** 最快发货日期 */
  const maxLeadTimeSpu = maxBy(spuList, spu => {
    return get(spu, s => s.leadTime.amount) || 0;
  });
  const maxLeadTimeDayjs = dayjs().add(
    get(maxLeadTimeSpu, m => m.leadTime.amount) || 0,
    'hour',
  );
  const maxLeadTimeWeekday = maxLeadTimeDayjs
    .day(maxLeadTimeDayjs.day())
    .format('dddd');
  const maxLeadTimeFormatted = maxLeadTimeDayjs.format('DD MMM');
  const maxLeadTimeStr = `${maxLeadTimeWeekday}, ${maxLeadTimeFormatted}`;

  const leadTimeList = (!!order ? order.items || [] : []).map(item => {
    return get(item, i => i.materialSpu.leadTime.amount);
  });
  const maxLeadtimeInHours = max(leadTimeList) || 0;
  const minLeadtimeInHours = min(leadTimeList) || 0;
  const maxLeadtimeInDays = maxLeadtimeInHours / 24;
  const minLeadtimeInDays = minLeadtimeInHours / 24;
  const shipmentDate = dayjs()
    .add(maxLeadtimeInDays, 'day')
    .format('YYYY-MM-DD');
  const dueDate = `${maxLeadtimeInDays}-${maxLeadtimeInDays + 1}`;

  return {
    maxLeadtimeInDays,
    minLeadtimeInDays,
    shipmentDate,
    dueDate,
    maxLeadTimeStr,
  };
}

export function calcOrderLeadTimeWithExpressTime(
  order: InquiryOrder,
  express: ShopExpress[],
) {
  const expressId = get(order, o => o.customerShipOption.expressId);
  const spuList = !!order ? order.items.map(item => item.materialSpu) : [];

  let leadTimeStr = '-';
  let estDeliveryStr = '-';

  if (
    !isValidArray(spuList) ||
    // 单位含有不为 HOURS 的交期直接不展示
    spuList.findIndex(s => s.leadTime.unit !== 'HOURS') > -1
  ) {
    return { leadTimeStr, estDeliveryStr };
  }

  const targetExpress = expressId
    ? (express || []).find(e => e.id == expressId)
    : null;
  const targetSpu = maxBy(spuList, s => get(s, s => s.leadTime.amount, 0));

  const totalHours = sum([
    get(targetSpu, t => t.leadTime.amount, 0),
    targetExpress ? get(targetExpress, t => t.leadTime.max, 0) : 0,
  ]);

  leadTimeStr = `${ceil(totalHours / 24)} days`;
  estDeliveryStr = dayjs().add(totalHours, 'hours').format('dddd, MMM. Do');

  return { leadTimeStr, estDeliveryStr };
}

// 从已有订单创建新订单
export function newInquiryOrderFromExisting(order: InquiryOrder) {
  for (const item of order.items || []) {
    item.id = undefined;
    for (const f of item.printFiles || []) {
      f.id = undefined;
    }
  }
  return new InquiryOrder({
    ...order,
    id: undefined,
    code: undefined,
    customerId: undefined,
  });
}

// 这边假设所有订单条目的文件 ID 都不重复，以文件 ID 进行订单条目 ID 的映射
export function mapOrderItemIdViaFileIds(
  fileIdToItemId: Record<Id, Id>,
  order: InquiryOrder,
) {
  const validItemIds = Object.values(fileIdToItemId).filter(v => !!v);
  for (const item of order.items) {
    if (item.id && validItemIds.includes(item.id)) {
      item.id = undefined;
    }
    if (item.printFiles && item.printFiles[0] && item.printFiles[0].fileId) {
      const itemId = fileIdToItemId[item.printFiles[0].fileId];
      item.id = itemId;
    }
  }
  return new InquiryOrder(order);
}

export function getModelFilesMapFromInquiryOrder(
  order: InquiryOrder,
): Partial<Record<Id, D3ModelFile>> {
  const m: Partial<Record<Id, D3ModelFile>> = {};
  try {
    for (const modelFile of order.modelFiles) {
      m[modelFile.id!] = modelFile;
    }
  } catch (e) {
    console.warn('error get model files map', order, e);
    try {
      for (const item of order.items || []) {
        for (const f of item.printFiles || []) {
          if (f.modelFile) {
            m[f.modelFile.id!] = f.modelFile;
          }
        }
      }
    } catch (e) {
      console.warn('error get model files map', order, e);
    }
  }
  return m;
}

// public functions
export function isOnlyMetalType(orderItems: InquiryOrderItem[]) {
  return isOnlyMaterialTypeNameContains('SLM', orderItems);
}

export function isOnlySLAType(orderItems: InquiryOrderItem[]): boolean {
  return isOnlyMaterialTypeNameContains('SLA', orderItems);
}

export function isOnlyMaterialTypeNameContains(
  keyword: string,
  orderItems: InquiryOrderItem[],
) {
  if (!isValidArray(orderItems)) return false;

  const respList: boolean[] = [];

  for (const item of orderItems) {
    const materialSpu = get(item, i => i.materialSpu);

    const materialTypeName =
      get(materialSpu, i => i.material.materialType.name) ||
      get(materialSpu, i => i.materialSnapshot.materialType.name, '');

    respList.push(materialTypeName.toUpperCase().includes(keyword));
  }

  if (!isValidArray(respList)) {
    return false;
  } else {
    return respList.filter(r => r).length === orderItems.length;
  }
}

export function readOrderReceiveAddress(
  order?: InquiryOrder,
): ReceiveAddress | undefined {
  const addressList = order?.deliver?.deliverAddress || [];
  return convertDeliverAddressToReceiveAddress(addressList[0]);
}

export function readShopOrderReceiveAddress(
  order?: ShopOrder,
): ReceiveAddress | undefined {
  if (!order) return;

  if (order.delivery && isValidArray(get(order.delivery, d => d.items))) {
    const [item] = order.delivery.items;

    const t = new ReceiveAddress({
      detail: item.detail,
      name: item.recipient,
      country: item.country,
      companyName: item.companyName,
      city: item.city,
      district: item.district,
      postcode: item.postcode,
      province: item.province,
      recipient: item.recipient,
      street: item.street,
      phoneNumber: item.contactInfo,
    });

    const id = calcAddressId(t) as Id;
    const { firstName, lastName } = parseReceiverName(t);

    return new ReceiveAddress({ ...t, id, firstName, lastName });
  }
}

/** 根据订单获取价格单位描述 */
export const getCurrencySymbol = (order: InquiryOrder) => {
  const type = order && order.currency ? order.currency : 'CNY';
  return CurrencySymbolMap[type] || '¥';
};

export const getShopOrderShipmentDate = (
  order: ShopOrder | Omit<ShopOrder, 'quotation'>,
) => {
  const leadTimes = compact(
    (order.items || []).map(i => get(i, i => i.materialSkuVO.leadTime)),
  ).filter(i => typeof i.amount == 'number');

  if (leadTimes.findIndex(s => s.unit !== 'DAYS') > -1) {
    return '-';
  }

  if (!isValidArray(leadTimes)) return '-';

  const maxDays = maxBy(leadTimes, l => l.amount).amount;

  return dayjs().add(maxDays, 'day').format('MMM D, YYYY');
};

export const getShopOrderLeadTimes = (
  o: ShopOrder | Omit<ShopOrder, 'quotation'>,
  shopExpress: ShopExpress[],
) => {
  const skus = Object.values(get(o, o => o.extraVO.skus, {}));

  let leadTimeStr = '-';
  let estDeliveryStr = '-';

  const expressId = get(
    o,
    o => o.requirements.customerSelections.delivery.expressId,
  );

  if (
    !expressId ||
    !isValidArray(skus) ||
    !isValidArray(shopExpress) ||
    // 单位含有不为 DAYS 的交期直接不展示
    skus.findIndex(s => s.leadTime.unit !== 'DAYS') > -1
  ) {
    return { leadTimeStr, estDeliveryStr };
  }

  const targetSku = maxBy(skus, s => get(s, s => s.leadTime.amount, 0));
  const targetExpress = (shopExpress || []).find(e => e.id == expressId);

  const totalDays = sum([
    get(targetSku, t => t.leadTime.amount, 0),
    get(targetExpress, t => t.leadTime.max, 0),
  ]);

  leadTimeStr = `${totalDays} ${i18nFormat('business days')}`;
  estDeliveryStr = dayjs().add(totalDays, 'day').format('dddd, MMM. Do');

  return { leadTimeStr, estDeliveryStr };
};

export const getShopOrderQuotationRfqDetailReason = (
  type: ShopOrderQuotationRfqReasonType | null,
) => {
  switch (type) {
    case 'large-production-price':
    case 'large-print-weight':
    case 'small-model':
    case 'large-edge':
    case 'special-material':
    case 'some-handle-method':
      return `We're always working on providing instant pricing, but some complexities require special care and attention.  kindly click "Request a Manual Quote", and our team of engineers will contact you with a quote that includes pricing, lead time, and other important details.`;
    case 'large-delivery-price':
      return `The Freight calculation may take some extra time. If you need a faster response, kindly click "Request a Manual Quote", and our team of engineers will contact you with a quote that includes pricing, lead time, and other important details.`;
    case 'computing':
      return 'The model Process may take some extra time. If you need a faster response, kindly click "Request a Manual Quote", and our team of engineers will contact you with a quote that includes pricing, lead time, and other important details.';
    default:
      return `We're always working on providing instant pricing, but some complexities require special care and attention.  kindly click "Request a Manual Quote", and our team of engineers will contact you with a quote that includes pricing, lead time, and other important details.`;
  }
};

export const getShopOrderItemQuotationRfqDetailReason = (
  type: ShopOrderQuotationRfqReasonType | null,
  currency?: CurrencySymbolType,
) => {
  switch (type) {
    case 'large-production-price':
      return currency ? handleRFQPriceStringWithOption(currency, 'RFQ') : 'RFQ';
    case 'large-print-weight':
      return 'Overweight. automatic quotation may be inaccurate';
    case 'small-model':
      return 'model volume is too small, automatic quotation may be inaccurate';
    case 'large-edge':
      return 'length of one side of the model is too long, automatic quotation may be inaccurate';
    case 'special-material':
      return 'CNC/SLM/Ployget needs manual quote';
    case 'some-handle-method':
      return 'finish needs manual quote';
    case 'large-delivery-price':
      return '-';
    case 'computing':
      return 'calculating';
    default:
      return currency ? handleRFQPriceStringWithOption(currency, 'RFQ') : 'RFQ';
  }
};
