import { i18nFormat } from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import {
  calcOrderShipQuotation,
  CurrencySymbolType,
} from '@unionfab/ufc-shop-commons';
import { Spin } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { Ellipsis } from '@/commons/components/Element/ellipsis';

import { DeliveryTimeTitle } from '../../../../../../components/info/DeliveryTimeTitle';

export interface ExpressColumnData {
  express: S.Express;
  expressQuotation?: number;
  expressTimingDesc?: string;
  expressRestriction: string;
}

export function getColumns(
  currency: CurrencySymbolType,
  orderQuotation?: S.OrderQuotationV2,
): ColumnsType<S.ShopExpress> {
  return [
    {
      width: 160,
      key: 'expressName',
      title: i18nFormat('Shipping Method'),
      render: (_, r) => <Ellipsis maxWidth={150}>{r.name}</Ellipsis>,
    },
    {
      title: i18nFormat('Costs'),
      dataIndex: 'expressQuotation',
      key: 'expressQuotation',
      align: 'left',
      render(_, record) {
        if (S.get(orderQuotation, o => o.shipQuotationsCalculating, false)) {
          return <Spin size="small" />;
        }

        const { requiresManualQuote, quotation, expressQuotation } =
          calcOrderShipQuotation(
            record.id!,
            orderQuotation.shipQuotationsRequiresManualQuote,
            orderQuotation.shipExpressQuotations,
          );

        if (
          typeof quotation !== 'number' &&
          (requiresManualQuote || !expressQuotation)
        ) {
          return <span>{i18nFormat('RFQ')}</span>;
        }

        return S.toPriceStringWithOption(quotation, { currency });
      },
    },
    {
      key: 'expressTimingDesc',
      title: <DeliveryTimeTitle />,
      dataIndex: 'expressTimingDesc',
      render: (_, r) => r.deliveryStr,
    },
    {
      align: 'left',
      title: i18nFormat('Restriction'),
      key: 'expressRestriction',
      render: (_, r) => (
        <Ellipsis maxWidth={180}>
          {S.get(r, r => r.restrictionDescription, '')}
        </Ellipsis>
      ),
    },
  ];
}
