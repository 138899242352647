import {
  getShopExpresses,
  getUfcShopComposedApi as api,
  i18nDateTimeFormat,
  i18nFormat,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { D3ModelAvatar } from '@unionfab/ufc-shop-ui-commons';
import { Button, Spin } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.less';

interface UnpaidShopOrderCardProps {
  order: S.ShopOrder;
  className?: string;
}

export const UnpaidShopOrderCard = ({
  order,
  className,
}: UnpaidShopOrderCardProps) => {
  const navigator = useNavigate();
  const [loading, toggleLoading] = useState<boolean>(false);

  const { code, status, price, updatedAt, requirements } = order;
  const currency = S.get(requirements, p => p.quotation.currency);
  const isUnpaid = S.UnpaidInquiryOrderStatusList.includes(status);

  const { data } = useQuery(['express', order], {
    queryFn: () => {
      const country = S.get(order, o => o.delivery.items[0].country);

      if (typeof country == 'string') return getShopExpresses(country);

      return [];
    },
  });

  const { data: priceInfo, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    enabled: isUnpaid && !!order,
    queryKey: ['priceInfo', order],
    refetchInterval: q => (q && q.hasComputingItem ? 3000 : false),
    queryFn: () => {
      const expressId = S.get(
        order,
        o => o.requirements.customerSelections.delivery.expressId,
      );

      return api().shopOrderQueryApi.calcPrice(code, { expressId });
    },
  });

  if (!order) return <></>;

  const firstItem = S.isValidArray(order.items || []) ? order.items[0] : null;

  const totalPrice = useMemo(() => {
    if (!isUnpaid)
      return S.handleRFQPriceStringWithOption(
        currency,
        S.get(price, p => p.total),
      );

    if ((priceInfo && priceInfo.hasComputingItem) || isLoading) return <Spin />;

    if (status == 'WAIT_SUBMIT')
      return S.handleRFQPriceStringWithOption(
        currency,
        S.get(priceInfo, p => p.subTotal),
      );

    return S.handleRFQPriceStringWithOption(
      currency,
      S.get(priceInfo, p => p.total),
    );
  }, [priceInfo, price, isLoading, isUnpaid]);

  const onCheckQuote = async () => {
    try {
      toggleLoading(true);

      const order = await api().shopOrderQueryApi.queryShopOrderByCode(code);

      if (order) {
        const hasAddress = S.get(order, o => o.delivery.items, []).length > 0;

        toggleLoading(false);

        if (hasAddress) {
          return navigator(`/new_shop_quote/${code}/checkout`);
        }

        navigator(`/new_shop_quote/${code}`);
      } else {
        toggleLoading(false);
      }
    } catch (e) {
      toggleLoading(false);

      console.log('>>>UnpaidShopOrderCard>>>onCheckQuote', e);
    }
  };

  return (
    <div className={cn(styles.container, className)} key={order.id}>
      <div className={styles.orderCode}>
        {i18nFormat('Code')}:&nbsp;{code || '-'}
      </div>
      <div className={styles.content}>
        {firstItem ? (
          <D3ModelAvatar
            size={80}
            model={firstItem.modelFileVO}
            unit={firstItem.lengthUnit || 'MILLIMETER'}
          />
        ) : (
          i18nFormat('No files')
        )}

        <div className={styles.basicInfo}>
          <div className={styles.price}>{totalPrice}</div>
          <div>
            {i18nFormat('Status')}:&nbsp;
            {S.getOrderShopStatusByInquiryOrderStatus(status)}
          </div>
          <div>
            {i18nFormat('Lead Time')}:&nbsp;
            {S.getShopOrderLeadTimes(order, data).leadTimeStr}
          </div>
          {updatedAt && (
            <div>
              {i18nFormat('Last Updated')}:&nbsp;
              {i18nDateTimeFormat(dayjs(updatedAt).format('MMM D,YYYY'))}
            </div>
          )}
        </div>
      </div>
      <Button
        block
        loading={loading}
        onClick={onCheckQuote}
        className={styles.quoteBtn}
      >
        {i18nFormat('View Quote')}
      </Button>
    </div>
  );
};
