import { PaperClipOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { getModelFileMetrics, i18nFormat } from '@unionfab/ufc-shop-commons';
import { D3ModelAvatar } from '@unionfab/ufc-shop-ui-commons';
import { Popover, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import cn from 'classnames';
import { useEffect } from 'react';

import {
  getUseShopQuoteStoreByCode,
  loadD3ModelFileByIdsInStore,
  useD3ModelStore,
} from '@/stores';

import styles from './index.module.less';

export interface ShopQuoteItemReviewTableProps {
  className?: string;
  style?: Record<string, string | number>;

  orderCode: string;
}

/**
 * @refer OrderItemReviewTable 参考了该组件的设计
 */
export const ShopQuoteItemReviewTable = ({
  className,
  style,

  orderCode,
}: ShopQuoteItemReviewTableProps) => {
  const { shopOrder, shopOrderQuotation } = getUseShopQuoteStoreByCode(
    orderCode,
  )(o => ({
    shopOrder: o.shopOrder,
    shopOrderQuotation: o.shopOrderQuotation,
  }));
  const modelFileStore = useD3ModelStore();

  useEffect(() => {
    if (shopOrder && shopOrder.items) {
      const fileIds = S.get(
        shopOrder,
        s => (s.items || []).map(i => i.fileId),
        [],
      );

      loadD3ModelFileByIdsInStore(fileIds);
    }
  }, [shopOrder]);

  if (!shopOrder) return <></>;

  const renderEllipsisText = ({
    txt = '',
    rows = 1,
    suffixCount = 8,
  }: {
    txt: string;
    rows?: number;
    suffixCount?: number;
  }) => {
    const start = txt.slice(
      0,
      txt.length > suffixCount ? txt.length - suffixCount : 0,
    );
    const suffix = txt.slice(-suffixCount).trim();

    return (
      <Typography.Paragraph
        style={{ margin: 0 }}
        ellipsis={{
          suffix: (<span>{suffix}</span>) as any, // 使用 `<span>` 包裹，避免谷歌翻译报错，https://github.com/facebook/react/issues/11538#issuecomment-390386520
          rows,
          tooltip: txt,
        }}
      >
        {start.trim()}
      </Typography.Paragraph>
    );
  };

  const columns: ColumnsType<S.ShopOrderItem> = [
    {
      key: 'product',
      title: i18nFormat('Product'),
      render: (o: S.ShopOrderItem) => {
        const modelFile = o.fileId ? modelFileStore[o.fileId] : null;

        return (
          <Space direction="vertical" style={{ minWidth: 200 }}>
            <D3ModelAvatar key={o.id} size={80} model={modelFile} />
            {renderEllipsisText({
              txt: S.get(modelFile, m => m.name, ''),
              rows: 2,
            })}
            {renderEllipsisText({
              txt: getModelFileMetrics({ modelFile, length: o.lengthUnit }),
            })}
          </Space>
        );
      },
    },
    {
      key: 'sku',
      title: i18nFormat('SKU'),
      render: (o: S.ShopOrderItem) => {
        const materialVO = S.get(o, o => o.materialVO);
        const materialSku = S.get(o, o => o.materialSkuVO);
        const color = S.get(materialSku, f => f.materialSnapshot.color, '#fff');
        const handleMethodStr = S.get(
          o,
          o => o.handleRemark.method.join('/'),
          '',
        );

        return (
          <Space direction="vertical">
            <span className={styles.flexStart}>
              {i18nFormat('Material')}:&nbsp;
              {renderEllipsisText({ txt: materialVO.name, rows: 1 })}
            </span>
            <span className={styles.flexStart}>
              {i18nFormat('Color')}:&nbsp;
              {renderEllipsisText({ txt: color, rows: 1 })}
            </span>
            <span className={styles.flexStart}>
              {i18nFormat('Finish')}:&nbsp;
              {renderEllipsisText({
                txt: handleMethodStr || 'Standard',
                rows: 1,
              })}
            </span>
            {shopOrder.technology !== 'CNC' && (
              <span className={styles.flexStart}>
                {i18nFormat('Inserts')}:&nbsp;
                {S.get(o, o => o?.nutCount) || '-'}
              </span>
            )}
          </Space>
        );
      },
    },
    {
      key: 'price',
      width: 200,
      title: i18nFormat('Price'),
      render: (o: S.ShopOrderItem) => {
        const itemPrice = S.get(shopOrderQuotation, q =>
          (q.production.items || []).find(i => i.id == o.id),
        );

        const amount = S.get(itemPrice, i => i.price.amount);
        const isRfq = S.get(itemPrice, s => s.status !== 'OK');
        const perAmount = S.get(itemPrice, i => i.setPrice.amount);
        const currency = S.get(itemPrice, i => i.setPrice.currency);

        return (
          <Space direction="vertical" style={{ width: 150 }}>
            <span>
              {i18nFormat('Per')}:&nbsp;
              {isRfq
                ? S.handleRFQPriceStringWithOption(currency, 'RFQ')
                : typeof perAmount == 'number'
                ? S.toPriceStringWithOption(perAmount, { currency })
                : '-'}
            </span>
            <span>
              {i18nFormat('Qty')}:&nbsp;
              {S.get(o, i => i.printCount)}
            </span>
            <span>
              {i18nFormat('Price')}:&nbsp;
              {isRfq
                ? S.handleRFQPriceStringWithOption(currency, 'RFQ')
                : typeof amount == 'number'
                ? S.toPriceStringWithOption(amount, { currency })
                : '-'}
            </span>
          </Space>
        );
      },
    },
    {
      key: 'notes',
      title: i18nFormat('Notes'),
      render: (o: S.ShopOrderItem) => {
        const remark = S.get(o, o => o.remark.remark, '');
        const remarkFiles = S.get(o, o => o.remarkFileVO, []);

        return (
          <Space direction="vertical">
            <span
              className={styles.flexStart}
              style={{ alignItems: 'flex-start' }}
            >
              {i18nFormat('Remark')}:&nbsp;
              {renderEllipsisText({ txt: remark, rows: 3 })}
            </span>
            <span
              className={styles.flexStart}
              style={{ alignItems: 'flex-start' }}
            >
              {i18nFormat('Technical drawing')}:{' '}
              <Space direction="vertical" style={{ marginLeft: 8 }}>
                {remarkFiles.map(f => (
                  <div
                    key={f.id}
                    className={styles.technicalDrawingFileWrapper}
                  >
                    <PaperClipOutlined />
                    <Typography.Paragraph
                      ellipsis={{ rows: 1 }}
                      className={styles.fileName}
                    >
                      {f.name}
                    </Typography.Paragraph>
                  </div>
                ))}
              </Space>
            </span>
          </Space>
        );
      },
    },
  ];

  if (shopOrder.technology === 'CNC') {
    const adaptText = i18nFormat(
      'Considering that the product structure cannot be manufactured as per the drawings, would you be open to either revising the drawings or producing the product in separate parts and assemblies?',
    );

    columns.push(
      {
        key: 'tolerance',
        title: i18nFormat('Tolerance'),
        render(o: S.ShopOrderItem) {
          return (
            <div style={{ width: 170 }}>
              {o?.cncRequirements?.tolerance?.option}
              <Locations val={o?.cncRequirements?.tolerance?.otherRemark} />
            </div>
          );
        },
      },
      {
        key: 'surface-roughness',
        title: i18nFormat('Surface Roughness'),
        render(item: S.ShopOrderItem) {
          return (
            <div style={{ width: 170 }}>
              {item?.cncRequirements?.surfaceRoughness?.option}
              <Locations
                val={item?.cncRequirements?.surfaceRoughness?.otherRemark}
              />
            </div>
          );
        },
      },
      {
        key: 'locational-fit',
        title: i18nFormat('Locational Fit'),
        render(item: S.ShopOrderItem) {
          return (
            <div style={{ width: 360 }}>
              {(item?.cncRequirements?.locationFits || []).map(fit => (
                <div style={{ marginBottom: 6 }}>
                  {fit?.option}
                  <Locations val={fit?.location} />
                </div>
              ))}
            </div>
          );
        },
      },
      {
        key: 'part-marking',
        title: i18nFormat('Part Marking'),
        render(item: S.ShopOrderItem) {
          return (
            <div style={{ width: 100 }}>
              {item?.cncRequirements?.partMarking?.option}
            </div>
          );
        },
      },
      {
        key: 'allow-adapt',
        title: (
          <div style={{ width: 150, display: 'flex', alignItems: 'center' }}>
            <Popover content={<div style={{ maxWidth: 400 }}>{adaptText}</div>}>
              <Typography.Text ellipsis>{adaptText}</Typography.Text>
            </Popover>
          </div>
        ),
        render(item: S.ShopOrderItem) {
          const apa = item?.cncRequirements?.allowProductAdapt;
          return apa == null ? 'Yes' : apa ? 'Yes' : 'No';
        },
      },
      {
        key: 'inspection',
        title: i18nFormat('Inspection'),
        render(item: S.ShopOrderItem) {
          return (item?.cncRequirements?.inspectSelection || []).map(ins => (
            <div style={{ width: 400, marginBottom: 6 }}>
              <div className={styles.insTt}>{ins.title}</div>
              <div className={styles.insDesc}>{ins.desc}</div>
            </div>
          ));
        },
      },
    );
  }

  return (
    <div
      style={style}
      id="ShopQuoteItemReviewTable"
      className={cn(className, styles.container)}
    >
      <Table
        style={{ width: '100%', overflow: 'auto' }}
        rowKey="id"
        pagination={false}
        columns={columns}
        dataSource={shopOrder.items || []}
      />
    </div>
  );
};

ShopQuoteItemReviewTable.displayName = 'ShopQuoteItemReviewTable';

function Locations({ val }: { val?: string }) {
  if (val == null) return <></>;
  else return <div className={styles.locations}>Locations: {val}</div>;
}
