import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { useAsyncEffect } from 'ahooks';
import { Button, message, Space, Spin } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import cn from 'classnames';
import { useMemo, useState } from 'react';

import { ShopOrderAddressSelector } from '@/features/new_quote/components/select/ShopOrderAddressSelector';
import { ShopOrderAddressCard } from '@/features/new_quote/components/select/ShopOrderAddressSelector/ShopOrderAddressCard';
import { ReceiveAddressForm } from '@/features/receive_address/components/ReceiveAddress/ReceiveAddressForm';
import {
  doLoadAddressList,
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
  useReceiveAddressMgtStore,
} from '@/stores';

import styles from './index.module.less';

export interface ShopQuoteShippingAddressMgtProps {
  className?: string;
  style?: Record<string, string | number>;

  orderCode: string;
}

export const ShopQuoteShippingAddressMgt = ({
  className,
  style,

  orderCode,
}: ShopQuoteShippingAddressMgtProps) => {
  const addressStore = useReceiveAddressMgtStore;
  const { loading, addressList, createAddress, updateAddress } = addressStore();

  const [editingAddress, setEditingAddress] = useState<{
    type: 'add' | 'modify';
    address: S.ReceiveAddress;
    visibleType: 'address' | 'selector' | 'form';
  }>({ type: 'add', address: null, visibleType: 'address' });

  const shopOrderStore = getUseShopQuoteStoreByCode(orderCode);
  const { allowEdit, isLoading, shopOrder, shopOrderDraft } = shopOrderStore(
    s => ({
      allowEdit: s.allowEdit,
      shopOrder: s.shopOrder,
      isLoading: s.isLoading,
      shopOrderDraft: s.shopOrderDraft,
    }),
  );

  const curDeliveryAddress = useMemo(() => {
    if (!shopOrder) return null;

    if (S.isValidArray(S.get(shopOrder, o => o.delivery.items))) {
      return shopOrder.delivery.items[0];
    }
  }, [shopOrder]);

  useAsyncEffect(async () => {
    const addressList = await doLoadAddressList();

    if (!S.isValidArray(addressList) && !curDeliveryAddress) {
      setEditingAddress({
        type: 'add',
        visibleType: 'form',
        address: new S.ReceiveAddress(),
      });
    } else {
      addressStore.setState({ addressList });
    }
  }, []);

  const actionButtons = useMemo(() => {
    if (!editingAddress) return <></>;

    const props: ButtonProps = {
      className: styles.btn,
      disabled: !allowEdit,
    };

    let content;

    if (editingAddress.visibleType == 'address') {
      content = (
        <Button
          {...props}
          type="primary"
          onClick={() => {
            shopOrderStore.setState({ shopOrderDraft: shopOrder });

            setEditingAddress({ ...editingAddress, visibleType: 'selector' });
          }}
        >
          {i18nFormat('Change')}
        </Button>
      );
    }

    if (editingAddress.visibleType == 'selector') {
      content = (
        <Space
          className={styles.flexStart}
          style={{ justifyContent: 'flex-end' }}
        >
          <Button
            {...props}
            type="primary"
            style={{ width: 'unset' }}
            disabled={!S.isValidArray(addressList) && !curDeliveryAddress}
            onClick={() =>
              setEditingAddress({ ...editingAddress, visibleType: 'address' })
            }
          >
            {i18nFormat('Back')}
          </Button>
          <Button
            {...props}
            onClick={() =>
              setEditingAddress({
                type: 'add',
                visibleType: 'form',
                address: new S.ReceiveAddress(),
              })
            }
          >
            {i18nFormat('Add New Address')}
          </Button>
        </Space>
      );
    }

    return <div className={styles.actionButtons}>{content}</div>;
  }, [
    allowEdit,
    addressList,
    shopOrderDraft,
    curDeliveryAddress,
    editingAddress,
  ]);

  const onHandleAddress = async (
    address: S.ReceiveAddress,
    type: 'add' | 'modify',
  ) => {
    const key = 'createReceiveAddress';

    let resp: boolean;

    try {
      if (type == 'add') {
        resp = await createAddress(address);
      } else {
        resp = await updateAddress(address);
      }

      if (resp) {
        /** 新增地址后若订单没有地址则选中改地址 */
        if (!curDeliveryAddress) {
          const d =
            S.ShopOrderDeliveryItemRequestParams.fromReceiveAddress(address);

          ShopOrderQuoteStoreModifiers.updateOrder({
            orderCode,
            onChange: o => {
              o.delivery.items = [d];
            },
          });

          setEditingAddress({
            ...editingAddress,
            address: null,
            visibleType: 'address',
          });

          return;
        }

        setEditingAddress({
          ...editingAddress,
          address: null,
          visibleType: 'selector',
        });
      }
    } catch (e) {
      message.error({ content: i18nFormat('Failed'), key });
      console.log('>>>ShopQuoteShippingAddressMgt>>>onHandleAddress', e);
    }
  };

  return (
    <div
      style={style}
      id="ShopQuoteShippingAddressMgt"
      className={cn(className, styles.container)}
    >
      <Spin
        spinning={
          loading || (isLoading && editingAddress.visibleType == 'selector')
        }
      >
        {editingAddress && editingAddress.visibleType == 'address' && (
          <ShopOrderAddressCard
            address={S.convertShopOrderDeliveryItemToReceiveAddress(
              curDeliveryAddress,
            )}
          />
        )}
        {editingAddress && editingAddress.visibleType == 'selector' && (
          <ShopOrderAddressSelector
            orderCode={orderCode}
            defaultAddressList={addressList}
            onSelectedAddress={() =>
              setEditingAddress({
                ...editingAddress,
                visibleType: 'address',
              })
            }
            onEdit={a =>
              setEditingAddress({
                type: 'modify',
                visibleType: 'form',
                address: new S.ReceiveAddress(a),
              })
            }
          />
        )}
        {editingAddress && editingAddress.visibleType == 'form' && (
          <ReceiveAddressForm
            layout="vertical"
            okText={i18nFormat('Save & Continue')}
            address={editingAddress.address}
            onClose={() => {
              setEditingAddress({ ...editingAddress, visibleType: 'selector' });
            }}
            onSubmit={async address =>
              onHandleAddress(address, editingAddress.type)
            }
          />
        )}

        {actionButtons}
      </Spin>
    </div>
  );
};

ShopQuoteShippingAddressMgt.displayName = 'ShopQuoteShippingAddressMgt';
