import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  // onGlobalCurrencyChange,
  CurrencySymbolType,
  SupportedCurrencies,
  CurrencyConfig,
  currencyBroadcast,
} from '@unionfab/ufc-shop-commons';

// 定义状态类型
interface StoreState {
  currency: CurrencySymbolType;
  currencyConfig: CurrencyConfig;
  setCurrency: (currency: CurrencySymbolType) => void;
}
const initialState: Omit<StoreState, 'setCurrency'> = {
  currency: 'USD',
  currencyConfig: SupportedCurrencies[0],
};
// 创建 Zustand store
const useCurrencyStore = create<StoreState>()(
  persist<StoreState>(
    (set, get) => ({
      ...initialState,
      setCurrency: currency => {
        // 先检查是否需要更新
        if (currency === get().currency) {
          console.log('是否频繁setCurrency');
          return;
        }
        console.log('>>>>设置了zustand');
        console.log('>>>>设置的currency', currency);
        console.log('>>>>设置的get().currency', get().currency);
        // 广播前检查是否真的发生了变化
        if (currency !== get().currency) {
          console.log('setCurrency>>>设置广播');
          currencyBroadcast.broadcast(currency);
        }
        const config =
          SupportedCurrencies.find(l => l.localeCode === currency) ||
          SupportedCurrencies[0];
        // 设置货币
        set({
          currency,
          currencyConfig: config,
        });
      },
    }),
    {
      name: 'currency-storage', // 存储的名称
      getStorage: () => localStorage, // 持久化写入LocalStorage
      // 监听广播同源多页面的币种变化，实现同源多页面币种同步
      // onRehydrateStorage: () => (state: StoreState) => {
      //   currencyBroadcast.subscribe(currency => {
      //     state.setCurrency(currency as CurrencySymbolType);
      //   });
      //   return state;
      // },
    },
  ),
);

export default useCurrencyStore;
