import { RollbackOutlined } from '@ant-design/icons';
import { CheckCard } from '@ant-design/pro-components';
import {
  getLocale,
  getOrderPayOrder,
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
  i18nFormat,
  UnionfabEventTracker,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Button, Image, Result, Spin } from 'antd';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import PaypalLogo from '@/assets/svgs/paypal.svg';
import SignsIcon from '@/assets/svgs/signs.svg';

import {
  getUseShopQuoteStoreByCode,
  useGlobalStore,
  useOrderListStore,
  useQuoteListStore,
} from '../../../../../stores';
import { OrderBankTransferBtn } from '../../../components/pay/bank-transfer/OrderBankTransferBtn';
import { OrderBankTransferDetailsByLocaleIsRu } from '../../../components/pay/bank-transfer/OrderBankTransferDetailsByLocaleIsRu';
import { ShopQuoteTotalTips } from '../ShopQuoteCheckoutPage/ShopQuoteCheckoutSummary/ShopQuoteTotalTips';
import styles from './index.module.less';
import { ShopQuotePaymentModal } from './ShopQuotePaymentModal';
import { ShopQuotePayOceanBtn } from './ShopQuotePayOceanBtn';
import { ShopQuotePayPalPayBtn } from './ShopQuotePayPalPayBtn';
import { ShopQuoteProcessingPage } from './ShopQuoteProcessingPage';

export interface ShopQuotePaymentHubProps {
  className?: string;
  style?: Record<string, string | number>;
}

export const ShopQuotePaymentHub = ({
  className,
  style,
}: ShopQuotePaymentHubProps) => {
  const navigate = useNavigate();
  const orderCode = useParams().orderCode;
  const useShopQuoteStore = getUseShopQuoteStoreByCode(orderCode);
  const {
    shopOrder,
    shopOrderPrice,
    isWaitingUserPay,
    isProcessing,
    isLoading,
  } = useShopQuoteStore();

  const { isDemoUser, hasAuthenticated } = useGlobalStore(g => ({
    isDemoUser: g.isDemoUser,
    hasAuthenticated: g.hasAuthenticated,
  }));

  /** 支付订单是否为手动关闭 */
  const [isManualShutdown, toggleManualShutdown] = useState<boolean>();
  const [paymentChannel, togglePaymentChannel] =
    useState<S.PaymentChannel>('paypal');

  useEffect(() => {
    if (!orderCode || (shopOrder && shopOrder.code !== orderCode)) {
      console.log('No order found, nav to home (from checkout page).');

      navigate('/');
    }

    if (shopOrder) {
      switch (shopOrder.status) {
        case 'WAIT_SUBMIT':
          console.warn('wait submit, nav to detail page');
          navigate(`/orders_history/${orderCode}`);
          break;
        case 'WAIT_REVIEW':
        case 'WAIT_CUSTOM_CONFIRM':
          break;
        case 'CUSTOM_CONFIRM':
          S.removeLocalStoragePayOrderId(
            shopOrder.code,
            getTenantIdFromGConfig(),
          );
          navigate(`/new_shop_quote/${orderCode}/payment-success`);
          break;
        default:
          console.warn('wait submit, nav to detail page on ', shopOrder.status);
          navigate(`/orders_history/${orderCode}`);
      }
    }
  }, [shopOrder, orderCode]);

  const allowPaypal = ['USD', 'EUR'].includes(
    S.get(shopOrder, () => shopOrder.requirements.quotation.currency),
  );

  const isCanPay = useMemo(() => {
    if (!shopOrderPrice) return false;

    if (shopOrderPrice.status == 'OK') return true;

    return false;
  }, [shopOrderPrice]);

  const onPayOrder = async (payOrder: S.PayOrder) => {
    if (!payOrder) return;

    try {
      const tenantId = getTenantIdFromGConfig();

      /** 判断支付订单是否过期 */
      if (typeof payOrder.validSec == 'number' && payOrder.validSec <= 0) {
        if (S.CanOrderPayStatusList.includes(payOrder.status)) {
          await getUfcShopComposedApi().ufcPaymentApi.closePayOrder({
            tenantId,
            payOrderId: payOrder.id,
            orderCode: shopOrder.code,
          });

          S.removeLocalStoragePayOrderId(shopOrder.code, tenantId);
        }

        return useShopQuoteStore.setState({
          isProcessing: false,
          isWaitingUserPay: false,
          paymentResult: {
            success: false,
            failureMessage: i18nFormat(
              'Payment time expired. Please try again later.',
            ),
          },
        });
      }

      if (payOrder.status === 'COMPLETE_PAY') {
        UnionfabEventTracker.getIns().addUserEventRecord({
          typeName: 'purchase',
          value: S.get(shopOrderPrice, o => o.price.amount),
        });

        S.removeLocalStoragePayOrderId(shopOrder.code, tenantId);

        useOrderListStore.setState({ orderList: [] });
        useQuoteListStore.setState({ orderList: [] });

        useShopQuoteStore.setState({
          isWaitingUserPay: false,
          paymentResult: { success: true, failureMessage: undefined },
        });

        navigate(`/new_shop_quote/${orderCode}/payment-success`);
      } else if (payOrder.status === 'CANCEL_PAY') {
        const msg =
          typeof isManualShutdown == 'boolean' && isManualShutdown
            ? i18nFormat('The current pay order seems to be canceled.')
            : i18nFormat('Payment time expired. Please try again later.');

        useShopQuoteStore.setState({
          isProcessing: false,
          isWaitingUserPay: false,
          paymentResult: { success: false, failureMessage: i18nFormat(msg) },
        });

        S.removeLocalStoragePayOrderId(shopOrder.code, tenantId);
      } else if (payOrder.status === 'EXCEPTION_PAY') {
        /** 这边清除 isWaitingUserPay 和 oceanpaymentPayUrl 会触发钱海支付窗口的关闭，关闭窗口会触发关闭回调 */
        useShopQuoteStore.setState({
          isProcessing: false,
          isWaitingUserPay: false,
          oceanpaymentPayUrl: undefined,
          paymentResult: {
            success: false,
            failureMessage:
              S.get(payOrder, o => o.statusTriggerData?.paymentDetails) ||
              'Payment failed, please try again or contact our customer service for help',
          },
        });

        S.removeLocalStoragePayOrderId(shopOrder.code, tenantId);
      }
    } catch (e) {
      console.log('>>>ShopQuotePaymentHub>>>onPayOrder>>>error', e);
    }
  };

  // 等待订单支付状态
  useQuery(['payment_result', shopOrder.code, isWaitingUserPay], {
    refetchInterval: 3000,
    enabled: typeof isWaitingUserPay == 'boolean' && isWaitingUserPay,
    onSuccess: onPayOrder,
    queryFn: () => {
      try {
        const orderCode = shopOrder.code;
        const tenantId = getTenantIdFromGConfig();
        const payOrderId = S.getPayOrderIdFromLocalStorage(orderCode, tenantId);

        if (payOrderId) {
          return getUfcShopComposedApi().ufcPaymentApi.getPayOrderInfo({
            orderCode,
            tenantId,
            payOrderId,
          });
        }
      } catch (e) {
        console.log('>>>ShopQuotePaymentHub>>>getPayOrderInfo error', e);
      }
    },
  });

  const orderInfo = useMemo(() => {
    return (
      <div className={styles.orderInfo}>
        <div className={styles.flexStart}>
          <SignsIcon />
          <p className={styles.desc}>
            {i18nFormat(
              'Thank you for choosing Unionfab; kindly proceed to complete payment to enable us to start your production.',
            )}
          </p>
        </div>
        <div className={styles.codeAndPrice}>
          <div className={styles.item}>
            <span className={styles.title}>{i18nFormat('Order ID')}:</span>
            <span className={styles.subTitle}>{shopOrder.code}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.title}>
              {i18nFormat('Total')}
              <span className={styles.subTitle}>
                ({i18nFormat('VAT excluded')})
              </span>
              &nbsp;
              <ShopQuoteTotalTips />
            </span>
            <Spin spinning={!shopOrderPrice || isLoading}>
              <span className={styles.price}>
                {shopOrderPrice && shopOrderPrice.total}
              </span>
            </Spin>
          </div>
        </div>
      </div>
    );
  }, [shopOrder, shopOrderPrice]);

  const [locale, setLocale] = useState('');
  const location = useLocation();
  useEffect(() => {
    setLocale(getLocale());
  }, [location]);
  return (
    <div
      id="ShopQuotePaymentHub"
      className={cn(className, styles.container)}
      style={style}
    >
      <div hidden={!isProcessing} style={{ height: '100%' }}>
        <ShopQuoteProcessingPage />
      </div>
      <div hidden={isProcessing}>
        {orderInfo}
        <Spin
          spinning={!shopOrderPrice || isLoading}
          wrapperClassName={styles.contentWrapper}
        >
          {/** 匿名用户或未登录用户不允许支付 */}
          {hasAuthenticated && !isDemoUser ? (
            <>
              {locale !== 'ru' && isCanPay && (
                <div className={styles.methodsWrapper}>
                  <div
                    className={styles.spaceBetween}
                    style={{ marginBottom: '1rem' }}
                  >
                    <p className={styles.title}>
                      {i18nFormat('Payment Methods')}
                    </p>
                    <Button
                      icon={<RollbackOutlined />}
                      onClick={() =>
                        navigate(`/new_shop_quote/${orderCode}/checkout`)
                      }
                    >
                      {i18nFormat('back')}
                    </Button>
                  </div>
                  <CheckCard.Group
                    className={styles.methods}
                    value={paymentChannel}
                    onChange={(value: S.PaymentChannel) =>
                      togglePaymentChannel(value)
                    }
                  >
                    <CheckCard
                      title={
                        <div className={styles.paymentMethodItem}>
                          <span>
                            {i18nFormat('PayPal(support Credit Card)')}
                          </span>
                        </div>
                      }
                      description={
                        <div className={styles.paypalLogo}>
                          <PaypalLogo />
                          <Image
                            width="100%"
                            preview={false}
                            src="https://ufc-oversea.oss-accelerate.aliyuncs.com/uoms/images/payment methods_1.png"
                          />
                        </div>
                      }
                      value={'paypal'}
                      disabled={!allowPaypal}
                    />
                    <CheckCard
                      value="oceanpay"
                      title={
                        <div className={styles.paymentMethodItem}>
                          <span>{i18nFormat('Credit Card')}</span>
                        </div>
                      }
                      description={
                        <Image
                          width="80%"
                          height={30}
                          preview={false}
                          src="https://ufc-oversea.oss-accelerate.aliyuncs.com/uoms/images/payment methods_1.png"
                        />
                      }
                    />
                    <CheckCard
                      title={
                        <div className={styles.paymentMethodItem}>
                          <span>{i18nFormat('Bank Transfer')}</span>
                        </div>
                      }
                      value="bank-transfer"
                    />
                  </CheckCard.Group>
                  <div className={styles.submit}>
                    {paymentChannel === 'paypal' && allowPaypal && (
                      <div className={styles.papalBtnWrapper}>
                        <p className={styles.tips}>
                          {i18nFormat(
                            'You can bind credit card on your paypal. PayPal can only pay in US dollars and Euros;',
                          )}
                        </p>
                        <ShopQuotePayPalPayBtn
                          orderCode={orderCode}
                          className={styles.papalBtn}
                          onClosePayOrder={() => toggleManualShutdown(true)}
                          onCreatePayOrder={() => toggleManualShutdown(null)}
                        />
                      </div>
                    )}
                    {paymentChannel === 'oceanpay' && (
                      <ShopQuotePayOceanBtn
                        orderCode={orderCode}
                        onClosePayOrder={() => toggleManualShutdown(true)}
                        onCreatePayOrder={() => toggleManualShutdown(null)}
                      />
                    )}
                    {paymentChannel === 'bank-transfer' && (
                      <div>
                        <p className={styles.tips}>
                          {i18nFormat(
                            'To pay by wire transfer using your bank account, you need to visit your nearest bank branch with the wire transfer information.',
                          )}
                        </p>
                        <OrderBankTransferBtn
                          orderCode={orderCode}
                          canPay={true}
                          onOpenTransferPage={() => {
                            navigate(
                              `/new_shop_quote/${orderCode}/bank-transfer`,
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {locale !== 'ru' && shopOrderPrice && !isCanPay && (
                <Result
                  status="warning"
                  className={styles.rfqResult}
                  title={i18nFormat(
                    'The order is under quoting, contact customer service for progress.',
                  )}
                  extra={
                    <Button
                      type="primary"
                      onClick={() => {
                        if (
                          S.get(shopOrder, o => o.delivery.items, []).length > 0
                        ) {
                          return navigate(
                            `/new_shop_quote/${orderCode}/checkout`,
                          );
                        }

                        navigate(`/new_shop_quote/${orderCode}`);
                      }}
                    >
                      {i18nFormat('View Quote')}
                    </Button>
                  }
                />
              )}
              {locale === 'ru' && (
                <OrderBankTransferDetailsByLocaleIsRu
                  code={orderCode}
                  total={shopOrderPrice && (shopOrderPrice.total as any)}
                  currency={shopOrderPrice?.price.currency}
                />
              )}
            </>
          ) : (
            <Result
              status="warning"
              className={styles.rfqResult}
              title={i18nFormat('Please log in before paying for the order.')}
              extra={
                <Button
                  type="primary"
                  onClick={() => {
                    if (
                      S.get(shopOrder, o => o.delivery.items, []).length > 0
                    ) {
                      return navigate(`/new_shop_quote/${orderCode}/checkout`);
                    }

                    navigate(`/new_shop_quote/${orderCode}`);
                  }}
                >
                  {i18nFormat('View Quote')}
                </Button>
              }
            />
          )}
        </Spin>

        <ShopQuotePaymentModal
          orderCode={orderCode}
          paymentChannel={paymentChannel}
          onClosePayOrder={() => toggleManualShutdown(true)}
        />
      </div>
    </div>
  );
};

ShopQuotePaymentHub.displayName = 'ShopQuotePaymentHub';
