import { create, StoreApi, UseBoundStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  getMallGroup,
  getTenantConfigByKeys,
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
  getUploadAuthForOverseasCustomer,
  RecoveryEmailType,
  setUfcShopRuntimeEnv,
} from '../../apis';
import {
  FileStoreAuth,
  get,
  TenantConfig,
  CurrencySymbolType,
  SupportedCurrencies,
} from '../../schema';
import {
  decodeAccessToken,
  AccessTokenData,
  UfcShopTokenUtils,
} from '../../skeleton';
import {
  loginOrdinary as _loginOrdinary,
  sendRecoveryEmail as _sendRecoveryEmail,
} from '../auth';
import {
  currencyBroadcast,
  CurrencyBroadcastManager,
} from '@unionfab/ufc-shop-commons';

export type Locale = 'en-US' | 'zh-CN';

export interface ActionState {
  loginOrdinary?: boolean;
  loadTenantConfig?: boolean;
  loadQuotationGroupId?: boolean;
}

// TODO: split this.
export interface GlobalState {
  initialized: boolean;
  init: () => void;

  showHeader: boolean;
  setShowHeader: (show: boolean) => void;

  locale: Locale;
  stores: Array<{ storeId: string; endpoint: string }>;

  user?: AccessTokenData;
  customerName?: string;

  giveawayModal: boolean;

  actionState: ActionState;

  loginOrdinary: () => Promise<void>;
  loadUser: (force?: boolean) => void;
  sendRecoveryEmail: (data: RecoveryEmailType) => Promise<void>;

  clearToken: () => void;

  isSiderCollapsed: boolean;
  setIsSidebarCollapsed: (v: boolean) => void;

  // 海外报价组
  tenantConfig?: Partial<TenantConfig>;
  loadTenantConfig: (reload?: boolean) => Promise<void>;

  // 客户的报价组
  quotationGroupId?: string;
  loadingQuotationGroupId?: boolean;
  loadQuotationGroupId: (reload?: boolean) => Promise<void>;

  fileStoreAuth?: FileStoreAuth;
  loadFileStoreAuth: () => Promise<FileStoreAuth | undefined>;

  hasAuthenticated: boolean;
  isDemoUser: boolean;

  isRefreshingAccessToken: boolean;
  refreshAccessToken: () => Promise<void>;
  currency: CurrencySymbolType;
  setCurrency: (currency: CurrencySymbolType) => void;
}

async function _loadQuotationGroupId(): Promise<string> {
  const accountInfo = await getUfcShopComposedApi().userApi.getAccountProfileInfo();

  if (get(accountInfo, a => a.quotationGroup.id)) {
    return accountInfo.quotationGroup.id;
  }

  const groupInfo = await getMallGroup(getTenantIdFromGConfig());

  if (!groupInfo) {
    throw new Error('no quotation group found');
  }

  return groupInfo.data.id;
}

async function _loadTenantConfig(): Promise<Partial<TenantConfig>> {
  const conf = await getTenantConfigByKeys(window?.gConfig?.tenantId as any, [
    'overseaQuotationGroup',
    'modelStoreId',
  ]);
  const tenantConfig = conf || ({} as Partial<TenantConfig>);

  return tenantConfig;
}
export const useGlobalStore: UseBoundStore<
  StoreApi<GlobalState>
> = create<GlobalState>()(
  devtools(
    (set, get) => {
      const setIsSidebarCollapsed = (isSiderCollapsed: boolean) =>
        set({ isSiderCollapsed });

      const loadUser = (force?: boolean) => {
        const user = decodeAccessToken(UfcShopTokenUtils.getAccessToken(force));

        setUfcShopRuntimeEnv({ user });

        const { firstName, lastName, nickname } = user || {
          firstName: '',
          lastName: '',
          nickname: '',
        };
        const customerName =
          firstName && lastName
            ? `${firstName || '-'} ${lastName || '-'}`
            : `${nickname}`;

        set({
          user,
          customerName: customerName,
          isDemoUser: UfcShopTokenUtils.isDemoUser(),
          hasAuthenticated: UfcShopTokenUtils.isTokenValid(),
        });
      };

      const sendRecoveryEmail = async (data: RecoveryEmailType) => {
        await _sendRecoveryEmail(data);
      };

      const clearToken = () => {
        UfcShopTokenUtils.clearToken();

        loadUser();
      };

      const setActionState = (newState: Partial<ActionState>) => {
        const prev = get().actionState;
        const res: ActionState = { ...prev };
        for (const k0 of Object.keys(newState)) {
          const k = k0 as keyof ActionState;
          if (res[k] && newState[k] != null) {
            res[k] = newState[k];
          }
        }
        set({ actionState: res });
      };

      const loginOrdinary = async () => {
        try {
          setActionState({ loginOrdinary: true });

          await _loginOrdinary();
        } finally {
          setActionState({ loginOrdinary: false });
        }
      };

      const initActionState: ActionState = {};

      const loadTenantConfig = async (reload?: boolean) => {
        if (get().tenantConfig && !reload) return;

        try {
          setActionState({ loadTenantConfig: true });
          const tenantConfig = await _loadTenantConfig();
          set({ tenantConfig });
        } finally {
          setActionState({ loadTenantConfig: false });
        }
      };

      const loadQuotationGroupId = async (reload?: boolean) => {
        if (get().quotationGroupId && !reload) {
          return;
        }

        try {
          setActionState({ loadQuotationGroupId: true });
          const r = await _loadQuotationGroupId();
          set({ quotationGroupId: r });
        } finally {
          setActionState({ loadQuotationGroupId: false });
        }
      };

      const stores = [
        {
          endpoint: 'oss-accelerate.aliyuncs.com',
          storeId: 'FILE_STORE-45' as any,
        },
      ];

      const loadFileStoreAuth = async () => {
        // 检查 token 是否过期
        if (!UfcShopTokenUtils.isTokenValid()) {
          return;
        }

        const fileStoreAuth = await getUploadAuthForOverseasCustomer(
          'MODEL',
          stores,
        );

        set({ fileStoreAuth });
        return fileStoreAuth;
      };

      let isInitialized = false; // Flag to track initialization

      const init = () => {
        if (isInitialized) return;

        try {
          loadUser();

          // 使用统一的方法获取初始货币
          const initialCurrency = CurrencyBroadcastManager.getCurrentCurrency();
          setCurrency(initialCurrency);
          // 监听货币变化
          currencyBroadcast.subscribe(currency => {
            setCurrency(currency as CurrencySymbolType);
          });
        } finally {
          set({ initialized: true });
          isInitialized = true;
        }
      };

      const setShowHeader = (showHeader: boolean) => set({ showHeader });

      const refreshAccessToken = async () => {
        const { isRefreshingAccessToken: loading, user } = get();

        if (loading) return;

        const idToken = UfcShopTokenUtils.getIdToken(true);
        const accessToken = UfcShopTokenUtils.getAccessToken(true);

        /** id_token 过期直接清除token触发页面跳转逻辑 */
        if (!UfcShopTokenUtils.isTokenValid(idToken)) {
          clearToken();
          return;
        }

        let customerId;

        if (accessToken) {
          customerId = decodeAccessToken(accessToken).customerId;
        } else {
          customerId = user && user.customerId ? user.customerId : null;
        }

        if (!customerId) {
          console.log('>>>refreshAccessToken errror, lost customerId');
          return;
        }

        set({ isRefreshingAccessToken: true });

        const newToken = await getUfcShopComposedApi().ufcShopAuthApi.getAccessToken(
          idToken,
          customerId,
        );

        UfcShopTokenUtils.setToken(newToken);

        set({ isRefreshingAccessToken: false });
      };
      const setCurrency = async (currency: CurrencySymbolType) => {
        set({ currency });
      };
      const initState: GlobalState = {
        locale: 'en-US',
        giveawayModal: false,
        stores,
        initialized: false,
        init,
        showHeader: true,
        setShowHeader,

        actionState: initActionState,

        loadUser,
        loginOrdinary,
        sendRecoveryEmail,

        clearToken,

        isSiderCollapsed: false,
        setIsSidebarCollapsed,

        loadTenantConfig,
        loadQuotationGroupId,

        loadFileStoreAuth,

        hasAuthenticated: false,
        isDemoUser: false,

        isRefreshingAccessToken: false,
        refreshAccessToken,
        currency: 'USD', // 默认币种
        setCurrency, // 设置币种
      };

      return initState;
    },
    { name: 'global-store' },
  ),
);
