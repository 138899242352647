import {
  getOrderShopStatusByInquiryOrderStatus,
  i18nFormat,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Descriptions } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { useEffect } from 'react';

import { useOrderDetailsStore } from '@/stores';

import styles from './index.module.less';

export const OrderDetailPanel = ({
  className,
  shopPrice,
  orderCode,
}: {
  className?: string;
  shopPrice?: S.ShopOrderPrice;
  orderCode?: string;
}) => {
  const { paymentInfo, order, priceInfo, shopQuotation, loadOrder } =
    useOrderDetailsStore();
  useEffect(() => {
    loadOrder(orderCode);
  }, []);

  if (!order) return <></>;

  const showShopPrice = S.UnpaidInquiryOrderStatusList.includes(order.status);
  const hasRFQItem = (shopQuotation?.production.items || []).find(
    v => v.status !== 'OK',
  );
  return (
    <div className={cn(styles.orderDetailPanel, className)}>
      {order && (
        <>
          <ShippingAddressDetail order={order} />
          <OrderDetailPaymentBlock order={order} paymentInfo={paymentInfo} />
          {showShopPrice && shopPrice ? (
            <ShopOrderDetailBillingSummary
              order={order}
              shopPrice={shopPrice}
            />
          ) : (
            <OrderDetailBillingSummary
              order={order}
              priceInfo={priceInfo}
              hasRFQItem={!!hasRFQItem}
            />
          )}
        </>
      )}
    </div>
  );
};

export const ShippingAddressDetail = ({ order }: { order: S.InquiryOrder }) => {
  const curAddress =
    order.deliver.deliverAddress[0] || ({} as S.DeliverAddress);

  return (
    <Descriptions title={i18nFormat('Shipping Address')} column={1}>
      <Descriptions.Item label={i18nFormat('Company name')}>
        {S.get(curAddress, c => c.companyName, '-')}
      </Descriptions.Item>
      <Descriptions.Item label={i18nFormat('Consignee')}>
        {curAddress.recipient}
      </Descriptions.Item>
      <Descriptions.Item label={i18nFormat('Address')}>
        {S.formateAddressToString(curAddress)}
      </Descriptions.Item>
      <Descriptions.Item label={i18nFormat('Phone')}>
        {curAddress.contactInfo}
      </Descriptions.Item>
    </Descriptions>
  );
};

export const OrderDetailPaymentBlock = ({
  order,
  paymentInfo,
}: {
  order: S.InquiryOrder;
  paymentInfo?: S.PaymentInfo;
}) => {
  const paid = (order as any)?.payStatus === 'COMPLETE_PAY';
  if (paid && paymentInfo && paymentInfo.id) {
    const isPayPal = paymentInfo.channel === 'PAYPAL';
    return (
      <Descriptions title={i18nFormat('Payment')} column={1}>
        <Descriptions.Item label={'Pay Method'}>
          {isPayPal ? 'PayPal' : 'VISA'}
        </Descriptions.Item>
        {!isPayPal && paymentInfo?.oceanPay?.orderNumber && (
          <Descriptions.Item label="Pay Order No.">
            {paymentInfo?.oceanPay?.orderNumber}
          </Descriptions.Item>
        )}
        {isPayPal && paymentInfo?.payPal?.order?.id && (
          <Descriptions.Item label={i18nFormat('PayPal ID')}>
            {paymentInfo?.payPal?.order?.id}
          </Descriptions.Item>
        )}
        <Descriptions.Item label={i18nFormat('Paid Time')}>
          {dayjs(paymentInfo.completedAt).isValid()
            ? dayjs(paymentInfo.completedAt).format('DD/MM/YYYY HH:mm:ss')
            : '-'}
        </Descriptions.Item>
      </Descriptions>
    );
  } else {
    return (
      <Descriptions title={i18nFormat('Payment')} column={1}>
        <Descriptions.Item label="Pay ID">-</Descriptions.Item>
        <Descriptions.Item label={i18nFormat('Paid Time')}>-</Descriptions.Item>
      </Descriptions>
    );
  }
};

export const OrderDetailBillingAddress = ({
  order,
}: {
  order: S.InquiryOrder;
}) => {
  return (
    <Descriptions
      title={
        !order.hasConfirmedByCustomer
          ? i18nFormat('Quote Info')
          : i18nFormat('Order Info')
      }
      column={1}
    >
      <Descriptions.Item label={i18nFormat('Code')}>
        {S.get(order, o => o.code) || '-'}
      </Descriptions.Item>
      <Descriptions.Item
        label="Status"
        contentStyle={{ color: '#f89902' }}
        labelStyle={{ color: '#f89902', fontWeight: 'bold' }}
      >
        {order && getOrderShopStatusByInquiryOrderStatus(order.status)}
      </Descriptions.Item>
      <Descriptions.Item label={i18nFormat('Created At')}>
        {dayjs(order.createdAt).isValid()
          ? dayjs(order.createdAt).format('DD/MM/YYYY HH:mm:ss')
          : '-'}
      </Descriptions.Item>
    </Descriptions>
  );
};

export const ShopOrderDetailBillingSummary = ({
  order,
  shopPrice,
}: {
  order: S.InquiryOrder;
  shopPrice: S.ShopOrderPrice;
}) => {
  if (!shopPrice) return <></>;

  const isRfq = S.get(shopPrice, p => p.production.status) !== 'OK';
  const minProductionFee = S.get(
    shopPrice,
    p => p.production.minProductionFee.amount,
  );

  return (
    <Descriptions
      title={i18nFormat('Order Summary')}
      column={1}
      labelStyle={{ minWidth: 160 }}
    >
      <Descriptions.Item
        label={i18nFormat('Subtotal')}
        style={{ paddingBottom: 6 }}
      >
        {S.handleRFQPriceStringWithOption(
          S.get(shopPrice, p => p.production.price.currency),
          shopPrice.subTotal,
        )}
      </Descriptions.Item>
      <Descriptions.Item
        style={{ padding: 0 }}
        label={i18nFormat('Production Fee')}
        labelStyle={{ paddingLeft: 10, fontSize: 12, fontFamily: 'PingFangSC' }}
      >
        {shopPrice.productionFee}
      </Descriptions.Item>
      <Descriptions.Item
        label={i18nFormat('Minimum Order Fee')}
        labelStyle={{ paddingLeft: 10, fontSize: 12, fontFamily: 'PingFangSC' }}
      >
        {isRfq
          ? '-'
          : S.toPriceStringWithOption(minProductionFee, {
              rfq: false,
              currency: order!.currency,
            })}
      </Descriptions.Item>
      <Descriptions.Item label={i18nFormat('Shipping')}>
        {S.handleRFQPriceStringWithOption(
          S.get(shopPrice, p => p.production.price.currency),
          shopPrice.shipping,
        )}
      </Descriptions.Item>
      {!!shopPrice.couponApplied && (
        <Descriptions.Item label={i18nFormat('Discount')}>
          -&nbsp;
          {shopPrice.discount}
        </Descriptions.Item>
      )}
      <Descriptions.Item label={i18nFormat('Total')}>
        {S.handleRFQPriceStringWithOption(
          S.get(shopPrice, p => p.production.price.currency),
          shopPrice.total,
        )}
      </Descriptions.Item>
    </Descriptions>
  );
};

export const OrderDetailBillingSummary = ({
  order,
  priceInfo,
  hasRFQItem,
}: {
  order: S.InquiryOrder;
  priceInfo: S.OrderPaymentPrice;
  hasRFQItem: boolean; //订单中item detail其中一个是否有待报价数据
}) => {
  return (
    <Descriptions
      column={1}
      labelStyle={{ minWidth: 160 }}
      title={i18nFormat('Order Summary')}
    >
      <Descriptions.Item
        label={i18nFormat('Subtotal')}
        style={{ paddingBottom: 6 }}
      >
        {hasRFQItem
          ? 'RFQ'
          : S.toPriceStringWithOption(priceInfo.subtotal, {
              rfq: false,
              currency: order!.currency,
            })}
      </Descriptions.Item>
      <Descriptions.Item
        style={{ padding: 0 }}
        label={i18nFormat('Production Fee')}
        labelStyle={{ paddingLeft: 10, fontSize: 12, fontFamily: 'PingFangSC' }}
      >
        {hasRFQItem
          ? '-'
          : S.toPriceStringWithOption(
              priceInfo.subtotal - priceInfo.minProductCostFee,
              {
                rfq: false,
                currency: order!.currency,
              },
            )}
      </Descriptions.Item>
      <Descriptions.Item
        label={i18nFormat('Minimum Order Fee')}
        labelStyle={{ paddingLeft: 10, fontSize: 12, fontFamily: 'PingFangSC' }}
      >
        {hasRFQItem
          ? '-'
          : S.toPriceStringWithOption(priceInfo.minProductCostFee, {
              rfq: false,
              currency: order!.currency,
            })}
      </Descriptions.Item>
      <Descriptions.Item label={i18nFormat('Shipping')}>
        {S.toPriceStringWithOption(priceInfo.shipping, {
          rfq: false,
          currency: order!.currency,
        })}
      </Descriptions.Item>
      {!!priceInfo.discount && priceInfo.discount !== 0 && (
        <Descriptions.Item label={i18nFormat('Discount')}>
          -&nbsp;
          {S.toPriceStringWithOption(priceInfo.discount, {
            currency: order!.currency,
          })}
        </Descriptions.Item>
      )}
      <Descriptions.Item label={i18nFormat('Total')}>
        {hasRFQItem
          ? 'RFQ'
          : S.toPriceStringWithOption(priceInfo.total, {
              rfq: false,
              currency: order!.currency,
            })}
      </Descriptions.Item>
    </Descriptions>
  );
};
