import {
  CurrencyAndAmount,
  CurrencySymbolType,
  getCurrencySymbolByType,
} from '../../common';
import { get, toFixedNumber } from '../../utils';

export const toPriceString = (n?: number) => {
  return n == null ? '' : toFixedNumber(n).toFixed(2);
};

export const toPriceStringWithCurrency = (p: CurrencyAndAmount) => {
  const amount = get(p, p => p.amount);
  const currency = get(p, p => p.currency);
  return toPriceStringWithOption(amount, { currency });
};

export const toPriceStringWithOption = (
  n: number | undefined,
  params: {
    rfq?: boolean;
    currency?: CurrencySymbolType;
  },
) => {
  const rfq = params.rfq == null ? false : params.rfq;
  const currency = params.currency == null ? 'USD' : params.currency;
  const currencySymbol = getCurrencySymbolByType(
    currency,
  ) as CurrencySymbolType;
  if (typeof n === 'number') {
    return `${currencySymbol}${toPriceString(n)}`;
  }

  return rfq ? handleRFQPriceStringWithOption(currencySymbol, 'RFQ') : '-';
};

/**
 * 根据货币类型，获取货币符号和价格
 * 只处理传入RFQ的情况
 * 为什么不直接使用toPriceStringWithOption，
 * 因为toPriceStringWithOption是根据货币类型和价格来获取货币符号和价格，
 * 但不处理原有符号的金额，不适用一些下单页有些地方的场景
 */
export const handleRFQPriceStringWithOption = (
  type: CurrencySymbolType,
  price: string,
): string => {
  if (price == 'RFQ') {
    return (getCurrencySymbolByType(type) ?? '') + ' RFQ';
  }
  return price || '-';
};
