import { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Dropdown, Menu, Space, Tooltip } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import cn from 'classnames';
import { useEffect, useMemo } from 'react';

import CouponIcon from '@/assets/svgs/coupon.svg';

import {
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
  useInquiryCouponStore,
  useShopExpressStore,
} from '../../../../../../stores';

import styles from './index.module.less';

export interface ShopQuoteCouponSelectProps {
  className?: string;
  style?: Record<string, string | number>;

  orderCode: string;
}

export const ShopQuoteCouponSelect = ({
  className,
  style,

  orderCode,
}: ShopQuoteCouponSelectProps) => {
  const shopOrderStore = getUseShopQuoteStoreByCode(orderCode);
  const { coupons = [], loadInquiryCoupons } = useInquiryCouponStore();
  const [validCouponCodes, setValidCouponCodes] = useState<
    string[] | undefined
  >([]);
  const {
    isLoading,
    shopOrder,
    couponCode,
    shopOrderPrice,
    ratioFromUsdToOrderCurrency,
  } = shopOrderStore(o => ({
    isLoading: o.isLoading,
    shopOrder: o.shopOrder,
    couponCode: o.couponCode,
    shopOrderPrice: o.shopOrderPrice,
    ratioFromUsdToOrderCurrency: o.ratioFromUsdToOrderCurrency,
  }));

  useEffect(() => {
    if (!S.isValidArray(coupons)) {
      loadInquiryCoupons();
    }
  }, []);
  // 获取当前物流组，切换物流时，如果物流组=[],说明不支持配送，不批量计算所有的优惠券
  const { expressList } = useShopExpressStore(s => ({
    expressList: s.shopExpresses,
  }));
  // 批量计算所有的优惠券，然后过滤出可以使用的优惠券
  useEffect(() => {
    if (!shopOrder?.requirements?.customerSelections?.delivery?.expressId) {
      return;
    }
    if (S.isValidArray(coupons) && expressList.length > 0) {
      const fetchShopOrderPrices = async () => {
        try {
          setValidCouponCodes([]);
          const ShopOrderPriceList =
            await ShopOrderQuoteStoreModifiers.batchQueryOrderCoupon(
              orderCode,
              coupons.map(ele => ele.code),
              shopOrder?.requirements?.customerSelections?.delivery?.expressId,
            );
          const validCouponCodes = Object.entries(ShopOrderPriceList)
            .map(([key, value]) => {
              if (value && value?.couponApplied) {
                return key.toLowerCase();
              }
            })
            .filter(ele => !!ele);
          setValidCouponCodes(validCouponCodes);
        } catch (error) {
          console.error('Error fetching validCouponCodes:', error);
        }
      };

      // 调用异步函数
      fetchShopOrderPrices();
    }
  }, [coupons, shopOrder?.requirements?.customerSelections?.delivery]);

  // 过滤出可以使用的 coupons
  const availableCoupons = useMemo(() => {
    // 这里底层检测函数式依赖这三个参数
    return coupons.filter(
      c =>
        !ShopOrderQuoteStoreModifiers.validateCouponAndReturnErrorMsgByOrderCode(
          orderCode,
          c,
        ) && validCouponCodes.includes(c.code),
    );
  }, [
    shopOrder,
    shopOrderPrice,
    ratioFromUsdToOrderCurrency,
    validCouponCodes,
  ]);
  const availableCouponCodes = availableCoupons.map(c => c.code);
  const menuItems: ItemType[] = [
    new S.InquiryCoupon({ code: "Don't choose coupon" }),
    ...(coupons || []).filter(
      c => c.status === 'UNUSED' && validCouponCodes.includes(c.code),
    ),
  ].map((c, index) => {
    const disabled =
      index === 0 ? false : isLoading || !availableCouponCodes.includes(c.code);

    const label = index === 0 ? c.code : c.descWithExpires;
    return {
      key: c.code,
      label: disabled ? (
        <Tooltip
          title={ShopOrderQuoteStoreModifiers.validateCouponAndReturnErrorMsgByOrderCode(
            orderCode,
            c,
          )}
        >
          {i18nFormat(label)}
        </Tooltip>
      ) : (
        i18nFormat(label)
      ),
      disabled,
      onClick: () => {
        // 添加选择优惠券的函数
        ShopOrderQuoteStoreModifiers.selectShopOrderCoupon(orderCode, c.code);
      },
    };
  });
  const coupon = coupons.find(
    c =>
      c.code === couponCode &&
      c.status === 'UNUSED' &&
      validCouponCodes.includes(c.code),
  );
  let selectedCouponDesc = '';

  if (!coupon) {
    selectedCouponDesc = `${availableCoupons.length} ${i18nFormat(
      'coupon(s) available',
    )}`;
  } else if (!!coupon && coupon.code.length > 0) {
    selectedCouponDesc = coupon.descWithExpires;
  }
  return (
    <div
      id="ShopQuoteCouponSelect"
      className={cn(className, styles.container)}
      style={style}
    >
      <Dropdown
        placement="bottomRight"
        trigger={['click', 'hover']}
        menu={{ items: menuItems }}
        rootClassName={styles.dropdown}
        getPopupContainer={() => document.getElementById('area') as HTMLElement}
      >
        <div className={styles.selectedCoupon} id="area">
          {!coupon || !coupon.code ? (
            <>
              <div className={styles.flexCenter}>
                <CouponIcon />
                &nbsp;
                <span>{i18nFormat('Choose a coupon')}</span>
              </div>
              <Space>
                <span>{selectedCouponDesc}</span>
                <DownOutlined />
              </Space>
            </>
          ) : (
            <>
              <span>{selectedCouponDesc}</span>
              <DownOutlined />
            </>
          )}
        </div>
      </Dropdown>
    </div>
  );
};

ShopQuoteCouponSelect.displayName = 'ShopQuoteCouponSelect';
