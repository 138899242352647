import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { InputNumber, Skeleton, Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { compact, debounce } from 'lodash';

import {
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
} from '../../../../../../stores';
import styles from './index.module.less';
import { ShopQuoteItemsTableActions } from './ShopQuoteItemsTableActions';
import { ShopQuoteItemAction } from './ShopQuoteTableItemAction';
import { ShopQuoteTableItemInfo } from './ShopQuoteTableItemInfo';

export interface ShopQuoteItemsTableProps {
  className?: string;
  style?: Record<string, string | number>;

  orderCode: string;
}

export const ShopQuoteItemsTable = ({
  className,
  style,

  orderCode,
}: ShopQuoteItemsTableProps) => {
  const store = getUseShopQuoteStoreByCode(orderCode);
  const {
    allowEdit,
    shopOrder,
    shopOrderQuotation,
    selectedOrderItemIds = [],
  } = store();

  const columns: ColumnsType<S.ShopOrderItem> = compact([
    {
      key: 'info',
      title: i18nFormat('Model & Specifications'),
      render: (_, r) => {
        if (!r.id) {
          return <Skeleton avatar paragraph={{ rows: 2 }} />;
        }

        return <ShopQuoteTableItemInfo orderCode={orderCode} itemId={r.id} />;
      },
    },
    {
      key: 'qty',
      title: i18nFormat('Qty'),
      render: (_, r) => {
        if (!allowEdit) return r.printCount;

        return (
          <InputNumber
            key={r.id}
            min={1}
            size="small"
            disabled={!allowEdit}
            value={r.printCount}
            onChange={debounce(n => {
              if (typeof n == 'number' && n > 0) {
                ShopOrderQuoteStoreModifiers.updateOrder({
                  orderCode,
                  onChange: o => {
                    const resp = o.items.find(i => i.id == r.id);
                    resp && (resp.printCount = n);
                  },
                });
              }
            }, 500)}
          />
        );
      },
    },
    {
      key: 'leadTime',
      title: i18nFormat('Lead Time'),
      render: (_, r) => {
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {S.get(r, r =>
              r.materialSkuVO.deliverLeadTime
                ? r.materialSkuVO.deliverLeadTime.enName
                : r.materialSkuVO.leadTimeStrFromHours,
            )}
          </span>
        );
      },
    },
    {
      key: 'price',
      title: i18nFormat('Price'),
      render: (_, r) => {
        const itemQuotation = shopOrderQuotation.production.items.find(
          i => i.id === r.id,
        );

        if (!itemQuotation || itemQuotation.status === 'COMPUTING') {
          return <Spin>{i18nFormat('Calculating')}</Spin>;
        }

        if (
          itemQuotation &&
          S.get(itemQuotation, r => r.reason, []).length > 0
        ) {
          const { reason, price } = itemQuotation;
          if (
            reason.length == 1 &&
            reason[0].type == 'large-production-price'
          ) {
            return (
              <div>
                <span style={{ color: '#ff9500' }}>{i18nFormat('EST')}</span>
                &nbsp;
                {S.toPriceStringWithOption(itemQuotation.price.amount, {
                  rfq: false,
                  currency: itemQuotation?.price?.currency,
                })}
              </div>
            );
          }

          return (
            <Typography.Paragraph
              style={{ width: 150 }}
              ellipsis={{ rows: 3, tooltip: true }}
            >
              {S.getShopOrderItemQuotationRfqDetailReason(
                reason[0].type,
                price?.currency,
              )}
            </Typography.Paragraph>
          );
        }
        if (itemQuotation.status === 'RFQ') {
          return i18nFormat(
            `${S.handleRFQPriceStringWithOption(
              itemQuotation?.price?.currency,
              'RFQ',
            )}`,
          );
        }

        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {S.toPriceStringWithOption(itemQuotation?.price?.amount, {
              rfq: false,
              currency: itemQuotation?.price?.currency,
            })}
          </span>
        );
      },
    },
    allowEdit && {
      width: 100,
      key: 'action',
      title: i18nFormat('Action'),
      fixed: 'right',
      render: (_, r: S.ShopOrderItem) => {
        return <ShopQuoteItemAction orderCode={orderCode} itemId={r.id} />;
      },
    },
  ]);

  return (
    <div
      id="ShopQuoteItemsTable"
      style={style}
      className={cn(className, styles.container)}
    >
      <ShopQuoteItemsTableActions orderCode={orderCode} />
      <Table
        rowKey="rowKey"
        columns={columns}
        pagination={false}
        dataSource={shopOrder ? shopOrder.items : []}
        rowSelection={{
          selectedRowKeys: selectedOrderItemIds,
          getCheckboxProps: () => ({ disabled: !allowEdit }),
          onChange: (keys: string[]) => {
            store.setState({ selectedOrderItemIds: keys });
          },
        }}
      />
    </div>
  );
};

ShopQuoteItemsTable.displayName = 'ShopQuoteItemsTable';
