import { getCurrencyExchangeRate } from '../../apis/shop-func-apis/shop-apis/order/order_payment';

// tips 先暂时只开放USD和EUR
export interface CurrencyConfig {
  label: string;
  localeCode: CurrencySymbolType;
}
/**货币符号映射 */
export const CurrencySymbolMap = {
  USD: '$',
  EUR: '€',
  CNY: '¥',
  GBP: '£',
  JPY: '¥',
  RUB: '₽',
  CAD: 'C$',
  AUD: 'A$',
  BRL: 'R$',
  SGD: 'S$',
  MXN: 'Mex$',
};
/**获取支持的货币列表 */
export const getSupportedCurrencyList = (): readonly string[] => {
  return [
    'USD $',
    'GBP £',
    'EUR €',
    'JPY ¥',
    'RUB ₽',
    'CAD C$',
    'AUD A$',
    'BRL R$',
    'SGD S$',
    'MXN Mex$',
  ] as const;
};
/**顶部加载的货币下拉列表，label和对应的CurrencySymbolType */
export const SupportedCurrencies: CurrencyConfig[] = [
  {
    label: 'USD(US Dollar)',
    localeCode: 'USD',
  },
  {
    label: 'EUR(Euro)',
    localeCode: 'EUR',
  },
];
/** 支持的货币类型 */
export type CurrencySymbolType = keyof typeof CurrencySymbolMap;

export interface CurrencyAndAmount {
  currency: CurrencySymbolType;
  amount: number;
}

/** 获取给定货币类型的单位 */
export function getCurrencySymbolByType(type: CurrencySymbolType): string {
  return CurrencySymbolMap[type];
}

/** 根据订单获取价格单位描述 */
// FIXME 逻辑有点混用了
export const getOrderCurrency = (currency: CurrencySymbolType): string => {
  return (
    getSupportedCurrencyList()
      .find(el => el.includes(currency))
      ?.split(' ')[1] || '$'
  );
};
export const convertAmountToTargetCurrency = async (
  amount: string | number | (string | number)[],
  source: CurrencySymbolType,
  target: CurrencySymbolType,
  isToFixed = true,
): Promise<string | number | (string | number)[]> => {
  try {
    if (Array.isArray(amount)) {
      const rate = await getCurrencyExchangeRate(source, target);
      return amount.map(a => {
        const num = typeof a === 'string' ? parseFloat(a.replace(/,/g, '')) : a;
        const result = (num || 0) * rate;
        return isToFixed ? result.toFixed(2) : result;
      });
    }

    const numericAmount =
      typeof amount === 'string'
        ? parseFloat(amount.replace(/,/g, ''))
        : amount;

    if (isNaN(numericAmount)) {
      throw new Error('Invalid amount format');
    }

    const rate = await getCurrencyExchangeRate(source, target);
    const result = (numericAmount || 0) * rate;
    return isToFixed ? result.toFixed(2) : result;
  } catch (error) {
    console.error('Currency conversion error:', error);
    return isToFixed ? '0.00' : 0;
  }
};

/** 货币变化广播 */
export class CurrencyBroadcastManager {
  private static instance: CurrencyBroadcastManager | null = null;
  private channel: BroadcastChannel | null = null;
  private readonly CHANNEL_NAME: string = 'global-currency-channel';

  private constructor() {
    if (typeof window !== 'undefined' && 'BroadcastChannel' in window) {
      this.channel = new BroadcastChannel(this.CHANNEL_NAME);
    }
  }

  static getInstance(): CurrencyBroadcastManager {
    if (!CurrencyBroadcastManager.instance) {
      CurrencyBroadcastManager.instance = new CurrencyBroadcastManager();
    }
    return CurrencyBroadcastManager.instance;
  }
  // 添加一个获取当前货币的方法
  static getCurrentCurrency(): CurrencySymbolType {
    return (JSON.parse(localStorage.getItem('currency-storage') || '{}')?.state
      ?.currency || SupportedCurrencies[0].localeCode) as CurrencySymbolType;
  }
  // 广播货币变化
  broadcast(currency: string): void {
    console.log(
      '调用了broadcast>>>>>>>is have channel' +
        this.channel +
        '>>>>>>>have currency' +
        currency,
    );
    if (this.channel) {
      this.channel.postMessage({ currency });
    }
  }
  // 监听货币变化
  subscribe(callback: (currency: string) => void): () => void {
    if (!this.channel) {
      console.warn('在此环境中不支持BroadcastChannel');
      return () => {};
    }

    const handler = (event: MessageEvent) => {
      console.log('调用了subscribe>>>>>>>');
      const { currency } = event.data;
      callback(currency);
    };

    this.channel.addEventListener('message', handler);
    return () => this.channel?.removeEventListener('message', handler);
  }
}

export const currencyBroadcast = CurrencyBroadcastManager.getInstance();
