import { BaseEntity, CurrencyAndAmount, Id } from '../../../common';
import { get, isValidArray } from '../../../utils';
import { toPriceStringWithOption } from './../../utils/utils';
import { ShopOrderQuotationRfqReason } from './ShopOrderQuotation';
import { ShopOrderQuotationStatus } from './types';
import * as S from '@unionfab/ufc-shop-commons';

/** 产品条目报价 */
export class ShopOrderProductionQuotationItem extends BaseEntity<ShopOrderProductionQuotation> {
  /** rfq 原因列表 */
  reason: ShopOrderQuotationRfqReason[] = [];

  // orderItemId
  id: Id;

  status: ShopOrderQuotationStatus;

  // 每套价格
  setPrice: CurrencyAndAmount;

  // 最终价格
  price: CurrencyAndAmount;

  constructor(data: Partial<ShopOrderProductionQuotationItem> = {}) {
    super(data);

    BaseEntity.assignInConstructor(this, data);
  }
}

/** 产品报价 */
export class ShopOrderProductionQuotation extends BaseEntity<ShopOrderProductionQuotation> {
  /** rfq 原因列表 */
  reason: ShopOrderQuotationRfqReason[] = [];

  /** 仅在获取报价接口中起效果，表示是否计算中 */
  status: ShopOrderQuotationStatus;

  // 最终价格
  price: CurrencyAndAmount;

  /** 最小订单费用，即起步价费用 */
  minProductionFee: CurrencyAndAmount;

  /** 条目报价 */
  items: ShopOrderProductionQuotationItem[] = [];

  get shopOrderSubtotalPrice() {
    const { status, price } = this;
    if (status !== 'OK')
      return S.handleRFQPriceStringWithOption(price.currency, 'RFQ');

    const currency = get(price, t => t.currency);
    const productPrice = get(price, p => p.amount);

    return toPriceStringWithOption(productPrice, { currency });
  }

  get shopOrderProductionPrice() {
    const { status, price } = this;

    if (status !== 'OK') return '-';

    const currency = get(price, t => t.currency);
    const totalPrice = get(price, p => p.amount, 0);
    const minProductionFee = get(this, p => p.minProductionFee.amount, 0);

    return toPriceStringWithOption(totalPrice - minProductionFee, {
      currency,
    });
  }

  constructor(data: Partial<ShopOrderProductionQuotation> = {}) {
    super(data);

    BaseEntity.assignInConstructor(this, data);

    this.items = this.items.map(
      item => new ShopOrderProductionQuotationItem(item),
    );
  }
}
